<template>
    <div class="inventory-container">
      <h2>Lo que tienes </h2>
  
      <div class="button-group">
        <button @click="showAddProductModal">Agregar stock</button>
        <router-link to="/detallesinventory">
  <button>Ver detalles</button>
</router-link>
      </div>
  
      <div class="search-bar">
        <input v-model="searchQuery" placeholder="Buscar producto..." />
        
      </div>
        
      <div class="table-container">
        <table class="product-table">
          <thead>
            <tr>
              <th>Nombre del Producto</th>
              <th>Categoría</th>
              <th>Cantidad</th>
              <th>Última Actualización</th>
              <!-- <th>Acciones</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredInventory" :key="item.product_id">
              <td>{{ item.product.name }}</td>
              <td>{{ getCategoryName(item.product.category_id) }}</td>
              <td>{{ item.total_quantity }}</td>
              <td>{{ item.last_update }}</td>
              <!-- <td>
                <button @click="showEditProductModal(item)">Editar</button>
                <button @click="deleteProduct(item.product_id)">Eliminar</button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
  
      <p v-if="message">{{ message }}</p>
  
      <!-- Modal para agregar y editar producto -->
      <div v-if="showProductModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeProductModal">&times;</span>
          <form @submit.prevent="isEditing ? updateProduct() : addProduct()">
            <div class="form-group">
              <label for="productName">Producto:</label>
              <select v-model="newProduct.product_id" id="productName" required>
                <option v-for="product in products" :key="product.id" :value="product.id">
                  {{ product.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="quantity">Cantidad:</label>
              <input type="number" v-model="newProduct.quantity" id="quantity" required />
            </div>
            <div class="form-group">
              <label for="type">Tipo:</label>
              <select v-model="newProduct.type" id="type" required>
                <option value="in">Entrada</option>
                <!-- <option value="out">Salida</option> -->
              </select>
            </div>
            <button type="submit">{{ isEditing ? 'Actualizar Producto' : 'Agregar Producto' }}</button>
          </form>
          <p v-if="message">{{ message }}</p>
        </div>
      </div>
  
      <div v-if="confirmDeleteProductId !== null" class="modal">
        <div class="modal-content">
          <p>¿Estás seguro de que deseas eliminar este producto?</p>
          <div class="button-group">
            <button @click="confirmDeleteProduct">Confirmar</button>
            <button @click="confirmDeleteProductId = null">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from '../plugins/axios'; // Asegúrate de que la ruta sea correcta
  
  export default {
    data() {
      return {
        showProductModal: false,
        isEditing: false,
        searchQuery: '',
        newProduct: {
          id: null,
          product_id: null,
          quantity: 0,
          type: 'in',
        },
        products: [],
        inventory: [],
        categories: [],
        confirmDeleteProductId: null,
        message: '',
      };
    },
    computed: {
      filteredInventory() {
        return this.inventory.filter(item =>
          item.product && item.product.name && item.product.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      },
    },
    methods: {
      fetchProducts() {
        axios.get('/productos')
          .then(response => {
            this.products = response.data;
          })
          .catch(error => {
            console.error('Error al cargar los productos:', error);
          });
      },
      fetchInventory() {
        axios.get('/inventory/cantidad')
          .then(response => {
            this.inventory = response.data.data;
          })
          .catch(error => {
            console.error('Error al cargar el inventario:', error);
          });
      },
      showAddProductModal() {
        this.isEditing = false;
        this.clearProductForm();
        this.showProductModal = true;
      },
      showEditProductModal(item) {
        this.isEditing = true;
        this.newProduct = { 
          id: item.product_id,
          product_id: item.product_id,
          quantity: item.total_quantity,
          type: item.type,
        };
        this.showProductModal = true;
      },
      closeProductModal() {
        this.showProductModal = false;
        this.clearProductForm();
      },
      addProduct() {
        axios.post('/inventarios', this.newProduct)
          .then(response => {
            if (response.data && response.data.success) {
              this.inventory.push(response.data.data);
              this.clearProductForm();
              this.showProductModal = false;
              this.message = response.data.message || 'Producto agregado con éxito';
              this.fetchInventory();
            } else {
              this.message = response.data.message || 'Error al agregar el producto';
            }
            this.clearMessageAfterTimeout();
          })
          .catch(error => {
            console.error('Error al guardar el producto:', error);
            this.message = 'Error al agregar el producto';
            this.clearMessageAfterTimeout();
          });
      },
      updateProduct() {
        axios.put(`/inventarios/${this.newProduct.id}`, this.newProduct)
          .then(response => {
            if (response.data && response.data.success) {
              const index = this.inventory.findIndex(item => item.product_id === this.newProduct.id);
              if (index !== -1) {
                this.inventory.splice(index, 1, response.data.data);
              }
              this.clearProductForm();
              this.showProductModal = false;
              this.message = response.data.message || 'Producto actualizado con éxito';
              this.fetchInventory();
            } else {
              this.message = response.data.message || 'Error al actualizar el producto';
            }
            this.clearMessageAfterTimeout();
          })
          .catch(error => {
            console.error('Error al actualizar el producto:', error);
            this.message = 'Error al actualizar el producto';
            this.clearMessageAfterTimeout();
          });
      },
      deleteProduct(productId) {
        this.confirmDeleteProductId = productId;
      },
      confirmDeleteProduct() {
        axios.delete(`/inventarios/${this.confirmDeleteProductId}`)
          .then(response => {
            if (response.data && response.data.success) {
              this.inventory = this.inventory.filter(item => item.product_id !== this.confirmDeleteProductId);
              this.message = response.data.message || 'Producto eliminado con éxito';
            } else {
              this.message = response.data.message || 'Error al eliminar el producto';
              this.fetchInventory();
            }
            this.clearMessageAfterTimeout();
          })
          .catch(error => {
            console.error('Error al eliminar el producto:', error);
            this.message = 'Error al eliminar el producto';
            this.clearMessageAfterTimeout();
          })
          .finally(() => {
            this.confirmDeleteProductId = null;
          });
      },
      clearProductForm() {
        this.newProduct = {
          id: null,
          product_id: null,
          quantity: 0,
          type: 'in',
        };
      },
      clearMessageAfterTimeout() {
        setTimeout(() => {
          this.message = '';
        }, 4000);
      },
      getCategoryName(categoryId) {
        const category = this.categories.find(cat => cat.id === categoryId);
        return category ? category.name : 'Desconocido';
      },
      fetchCategories() {
        axios.get('/categorias')
          .then(response => {
            this.categories = response.data;
          })
          .catch(error => {
            console.error('Error al cargar las categorías:', error);
          });
      },
    },
    mounted() {
      this.fetchProducts();
      this.fetchInventory();
      this.fetchCategories();
    },
  };
  </script>
  
  <style scoped>
  /* Mantén el estilo existente, puedes ajustarlo si es necesario */
  .inventory-container {
    max-width: 95%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  button {
    padding: 10px 15px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .search-bar {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .search-bar input {
    padding: 10px;
    width: 80%;
    max-width: 500px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .table-container {
    max-height: 600px;
    overflow-y: auto;
  }
  
  .product-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .product-table th, .product-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .product-table th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px 40px 20px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
  }
  </style>
  