<template>
  <div class="container-menu">
    <div class="menu-grid">
      <div
        v-for="item in menuItems"
        :key="item.id"
        class="menu-card"
        @click="handleMenuItemClick(item)"
      >
        <span>{{ item.title }}</span>
        <template v-if="item.id === 1.3">
          <div class="mesa-status">
            {{ mesasActivas }}/{{ mesasRegistradas }}
          </div>
        </template>
        <template v-if="item.active">
          <div class="submenu">
            <div @click="handleSubMenuItemClick(submenuItem)" v-for="submenuItem in item.submenu" :key="submenuItem.id" class="submenu-item">
              <span>{{ submenuItem.title }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../plugins/axios';

export default {
  data() {
    return {
      menuItems: [
        { 
          id: 1, 
          title: "Tiempo", 
          active: true,
          submenu: [
            { id: 1.1, title: "Cobrar Tiempo", path: "/tiempo" },
            { id: 1.2, title: "Detalles de Tiempos", path: "/detalles" },
            { id: 1.3, title: "Mesas en Linea", path: "/estado" }
          ]
        },
        // {
        //   id: 2,
        //   title: "Pedidos",
        //   active: false,
        //   submenu: [
        //     { id: 2.1, title: "Registrar Pedido", path: "/pedidos/registrar" },
        //     { id: 2.2, title: "Detalles de Pedidos", path: "/pedidos/detalles" },
        //   ],
        // },
        // {
        //   id: 3,
        //   title: "Actualizar Inventario",
        //   active: false,
        //   submenu: [
        //     { id: 3.1, title: "Productos", path: "/bodega" },
        //   ],
        // },
        // {
        //   id: 4,
        //   title: "Configuración",
        //   active: false,
        //   submenu: [
        //     { id: 4.1, title: "Productos y categorias", path: "/inventario" },
        //     { id: 4.2, title: "Configurar modulos", path: "/billar" },
        //     { id: 4.3, title: "Configurar usuarios", path: "/waitress"}
        //   ],
        // },
        // { 
        //   id: 5,
        //   title: "Resumen",
        //   active: false,
        //   submenu: [
        //     { id: 5.1, title: "Resumen de Ventas", path: "/resumenventa" },
        //     { id: 5.2, title: "Resumen de Tiempo", path: "/tiempo" },
        //     { id: 5.3, title: "Resumen de ventas Camareras", path: "/camarera" },
        //     { id: 5.4, title: "Resumen de Mesas", path: "/mesas" },
        //     { id: 5.5, title: "Resumen de Usuarios", path: "/usuarios" },
        //   ],
        // },
      ],
      mesasActivas: 0,
      mesasRegistradas: 0
    };
  },
  mounted() {
    this.fetchMesasStatus();
  },
  methods: {
    handleMenuItemClick(item) {
      item.active = !item.active;
      if (!item.submenu) {
        this.$router.push(item.path);
      }
    },
    handleSubMenuItemClick(submenuItem) {
      this.$router.push(submenuItem.path);
    },
    fetchMesasStatus() {
      axios.get('/mesas/status')
        .then(response => {
          const data = response.data;
          this.mesasActivas = data.mesas_activas;
          this.mesasRegistradas = data.mesas_registradas;
          
          // Actualiza el título del submenú "Mesas en Linea"
          const mesasSubmenu = this.menuItems[0].submenu.find(submenu => submenu.id === 1.3);
          mesasSubmenu.title = `Mesas en Linea ${this.mesasActivas}/${this.mesasRegistradas}`;
        })
        .catch(error => {
          console.error('Error al obtener el estado de las mesas:', error);
        });
    }
  },
};
</script>

<style scoped>
.container-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f0f2f5;
  min-height: 100vh;
}

.menu-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  margin-bottom: 40px;
}

.menu-card {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 40px;
  width: 250px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}

.menu-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

.menu-card span {
  display: block;
  margin-top: 10px;
}

.submenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.submenu-item {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submenu-item:hover {
  background-color: #f0f0f0;
}

.submenu-item span {
  border: 5px solid #dddddd; /* Agregar borde alrededor del submenú */
  border-radius: 10px; /* Borde redondeado */
}

.mesa-status {
  margin-top: 10px;
  font-size: 18px;
  color: #ffffff;
}
</style>
