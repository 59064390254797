<template>
  <div class="inventory-container">
      <h2>Tomar el Pedido</h2>
      <div class="button-group">
          <button @click="showPedidoModal = true">Agregar Pedido</button>
      </div>
      <p v-if="message">{{ message }}</p>
      <div class="search-bar">
          <input v-model="searchQuery" placeholder="Buscar producto..." />
      </div>
      <div class="table-wrapper">
          <table class="product-table">
              <thead>
                  <tr>
                      <th>Pedido</th>
                      <th>Usuaria</th>
                      <th>Cantidad</th>
                      <th>Total</th>
                      <th>Acciones</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="order in filteredOrders" :key="order.id">
                      <td>{{ order.id }}</td>
                      <td>{{ order.waitress.name }}</td>
                      <td>{{ order.total_quantity }}</td>
                      <td>{{ order.total_value | currency }}</td>
                      <td>
                          <button @click="showDetailsOrder(order)">Detalles</button>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
      <!-- Modal para tomar pedido -->
      <div v-if="showPedidoModal" class="modal">
          <div class="modal-content">
              <span class="close" @click="closePedidoModal">&times;</span>
              <h3>Tomar Pedido</h3>
              <div class="waitress-selection">
                  <h4>Seleccionar Camarera</h4>
                  <select v-model="newOrder.waitress_id">
                      <option v-for="waitress in waitresses" :key="waitress.id" :value="waitress.id">
                          {{ waitress.name }}
                      </option>
                  </select>
              </div>
              <div class="product-selection">
                  <h4>Seleccionar Productos</h4>
                  <div class="product-list">
                      <div v-for="product in products" :key="product.id" class="product-card" @click="addProductToOrder(product)">
                          <h4>{{ product.name }}</h4>
                          <p>Precio: {{ product.price | currency }}</p>
                      </div>
                  </div>
              </div>
              <div class="selected-products">
                  <h4>Productos Seleccionados</h4>
                  <div v-for="(item, index) in newOrder.items" :key="index" class="selected-product-card">
                      <div class="product-info">
                          <h4>{{ getProductById(item.product_id).name }}</h4>
                          <div class="quantity-controls">
                              <button @click="decrementQuantity(item)">-</button>
                              <input type="number" v-model.number="item.quantity" min="1" @input="updateItemTotal(item)" />
                              <button @click="incrementQuantity(item)">+</button>
                          </div>
                          <p>Precio: {{ item.price | currency }}</p>
                          <p>Total: {{ item.price * item.quantity | currency }}</p>
                      </div>
                      <button class="remove-product" @click="removeProductFromOrder(index)">Eliminar</button>
                  </div>
              </div>
              <div class="order-total">
                  <h4>Total del Pedido: {{ orderTotal | currency }}</h4>
              </div>
              <div class="actions">
                  <button @click="addOrder">Tomar Pedido</button>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import axios from '../plugins/axios';

export default {
data() {
  return {
    showPedidoModal: false,
    searchQuery: '',
    newOrder: {
      waitress_id: null,
      items: [],
    },
    waitresses: [],
    products: [],
    orders: [],
    message: '',
  };
},
computed: {
  filteredOrders() {
    if (!this.searchQuery) {
      return this.orders;
    }
    return this.orders.filter(order => {
      return order.items.some(item => item.product.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    });
  },
  orderTotal() {
    return this.newOrder.items.reduce((total, item) => total + item.price * item.quantity, 0);
  }
},
methods: {
  clearMessageAfterTimeout() {
    setTimeout(() => {
      this.message = '';
    }, 4000);
  },
  closePedidoModal() {
    this.showPedidoModal = false;
    this.clearOrderForm();
  },
  addProductToOrder(product) {
    const existingItem = this.newOrder.items.find(item => item.product_id === product.id);
    if (existingItem) {
      existingItem.quantity++;
    } else {
      this.newOrder.items.push({ product_id: product.id, price: product.price, quantity: 1 });
    }
  },
  removeProductFromOrder(index) {
    this.newOrder.items.splice(index, 1);
  },
  clearOrderForm() {
    this.newOrder = {
      waitress_id: null,
      items: [],
    };
  },
  addOrder() {
    axios.post('/orders', this.newOrder)
      .then(response => {
        if (response.data && response.data.data) {
          this.orders.push(response.data.data.order);
          this.clearOrderForm();
          this.showPedidoModal = false;
          this.message = response.data.message || 'Pedido tomado con éxito';
          window.location.reload(); // Recargar la página
        } else {
          this.message = response.data.message || 'Error al tomar el pedido';
        }
        this.clearMessageAfterTimeout();
      })
      .catch(error => {
        console.error('Error al tomar el pedido:', error);
        this.message = error.response.data.message || 'Error al tomar el pedido';
        this.clearMessageAfterTimeout();
      });
  },
  fetchOrders() {
    axios.get('/filtrar')
      .then(response => {
        if (response.data.success === false) {
          this.message = response.data.message || 'Error al cargar los pedidos';
        } else {
          this.orders = response.data;
        }
      })
      .catch(error => {
        console.error('Error al cargar los pedidos:', error);
        this.message = error.response?.data?.message || 'Error al cargar los pedidos';
      });
  },
  fetchWaitresses() {
    axios.get('/waitresses')
      .then(response => {
        this.waitresses = response.data;
      })
      .catch(error => {
        console.error('Error al cargar las camareras:', error);
      });
  },
  fetchProducts() {
    axios.get('/productos')
      .then(response => {
        this.products = response.data;
      })
      .catch(error => {
        console.error('Error al cargar los productos:', error);
      });
  },
  getProductById(id) {
    return this.products.find(product => product.id === id) || {};
  },
  incrementQuantity(item) {
    item.quantity++;
  },
  decrementQuantity(item) {
    if (item.quantity > 1) {
      item.quantity--;
    }
  },
  updateItemTotal(item) {
    item.total = item.price * item.quantity;
  }
},
mounted() {
  this.fetchProducts();
  this.fetchWaitresses();
  this.fetchOrders();
},
};
</script>

<style scoped>
.inventory-container {
max-width: 95%;
margin: 0 auto;
padding: 20px;
background-color: #f9f9f9;
border-radius: 10px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
text-align: center;
margin-bottom: 20px;
}

.button-group {
display: flex;
justify-content: space-between;
margin-bottom: 20px;
}

.search-bar input {
width: 100%;
padding: 10px;
margin-bottom: 20px;
border: 1px solid #ccc;
border-radius: 5px;
}

.product-table {
width: 100%;
border-collapse: collapse;
}

.product-table th,
.product-table td {
border: 1px solid #ccc;
padding: 10px;
text-align: left;
}

.product-table th {
background-color: #f2f2f2;
}

.modal {
display: block;
position: fixed;
z-index: 1;
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: auto;
background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
background-color: #fff;
margin: 5% auto;
padding: 20px;
border: 1px solid #888;
width: 90%;
max-width: 500px;
border-radius: 10px;
max-height: 90%;
overflow-y: auto;
}

.close {
color: #aaa;
float: right;
font-size: 28px;
font-weight: bold;
}

.close:hover,
.close:focus {
color: black;
text-decoration: none;
cursor: pointer;
}

.waitress-selection {
margin-bottom: 20px;
}

.product-selection {
margin-bottom: 20px;
}

.product-list {
display: flex;
flex-wrap: wrap;
gap: 5px;
max-height: 250px;
overflow-y: auto;
flex-direction: column;
}

.product-card {
background-color: #f2f2f2;
border: 1px solid #ccc;
border-radius: 10px;
padding: 10px;
text-align: center;
cursor: pointer;
}

.product-card:hover {
background-color: #e0e0e0;
}

.selected-products {
margin-bottom: 20px;
}

.selected-product-card {
background-color: #fff;
border: 1px solid #ccc;
border-radius: 10px;
padding: 10px;
margin-bottom: 10px;
}

.product-info {
display: flex;
justify-content: space-between;
align-items: center;
}

.quantity-controls {
display: flex;
align-items: center;
gap: 10px;
}

.quantity-controls button {
background-color: #ccc;
padding: 5px 10px;
border: none;
cursor: pointer;
border-radius: 5px;
}

.quantity-controls button:hover {
background-color: #bbb;
}

.remove-product {
background-color: #ff4d4d;
color: #fff;
padding: 5px 10px;
border: none;
cursor: pointer;
border-radius: 5px;
margin-top: 10px;
}

.remove-product:hover {
background-color: #e60000;
}

.order-total {
text-align: center;
margin-bottom: 20px;
}

.actions {
text-align: center;
}
</style>
