<template>
    <div class="historial-container">
        <h2>Historial de Recaudos</h2>
        <div class="table-container" v-if="historial.length">
            <table>
                <thead>
                    <tr>
                        <th>Fecha de Inicio</th>
                        <th>Fecha de Fin</th>
                        <th>Cantidad Total</th>
                        <th>Monto Total</th>
                        <th>Fecha de Cambio</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="recaudo in historial" :key="recaudo.id">
                        <td>{{ formatDate(recaudo.fecha_inicio) }}</td>
                        <td>{{ formatDate(recaudo.fecha_fin) }}</td>
                        <td>{{ recaudo.total_quantity }}</td>
                        <td>{{ formatCurrency(recaudo.total_amount) }}</td>
                        <td>{{ formatDate(recaudo.changed_at) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p v-else>No hay datos disponibles</p>
    </div>
</template>

<script>
  import axios from '../plugins/axios';

export default {
    data() {
        return {
            historial: []
        };
    },
    created() {
        this.fetchHistorial();
    },
    methods: {
        fetchHistorial() {
            axios.get('/historial-recaudos')
                .then(response => {
                    if (response.data.success) {
                        this.historial = response.data.data;
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.error('Error al obtener el historial de recaudos:', error);
                });
        },
        formatDate(date) {
            const d = new Date(date);
            const year = d.getFullYear();
            const month = ('0' + (d.getMonth() + 1)).slice(-2);
            const day = ('0' + d.getDate()).slice(-2);
            const hours = ('0' + d.getHours()).slice(-2);
            const minutes = ('0' + d.getMinutes()).slice(-2);
            const seconds = ('0' + d.getSeconds()).slice(-2);
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'USD' }).format(value);
        }
    }
};
</script>

<style scoped>
.historial-container {
    padding: 20px;
}

.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th, td {
    padding: 10px;
    border: 1px solid #ccc;
}

th {
    background-color: #f4f4f4;
}
</style>
