<template>
    <div>
      <MenuCom title="Título del menú" description="Descripción del menú" />
    </div>
  </template>
  
  <script>
  import MenuCom from "@/components/MenuCom.vue";
  
  export default {
    components: {
      MenuCom,
    },
  };
  </script>
  
  <style scoped>
  .container {
    margin: 8px;
    padding: 2px;
    border-radius: 8px;
  }
  
  /* Estilo para hacer el fondo del menú transparente */
  .MenuCom {
    background-color: transparent;
  }
  </style>
  