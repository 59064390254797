<template>
  <div class="tiempo-mesas">
    <h1>Tiempo de Mesas</h1>

    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

    <div class="total-general">
      <h2>Total TIempo: {{ formatTime(totalGeneral.tiempo) }}</h2>
      <h2>Total Dinero: {{ formatCurrency(totalGeneral.dinero) }}</h2>
    </div>
    <button @click="confirmarMarcarSeleccionadosPagados" class="mark-paid-button">Marcar Seleccionados como Pagados</button>
    <div class="dias-container">
      <div class="dia-card" v-for="dia in dias" :key="dia.fecha" @click="fetchMesasPorFecha(dia.fecha)">
        <div class="dia-header">
          <input type="checkbox" v-model="selectedDias" :value="dia.fecha">
          <h2>{{ dia.fecha }}</h2>
        </div>
        <p>Total Tiempo: {{ formatTime(dia.total_tiempo) }}</p>
        <p>Total Dinero: {{ formatCurrency(dia.total_dinero) }}</p>
      </div>
    </div>

    <div v-if="mesas.length > 0" class="mesas-container">
      <h2>Mesas para la fecha: {{ fechaSeleccionada }}</h2>
      <div class="mesa-card" v-for="mesa in mesas" :key="mesa.mesa_id" @click="mostrarDetallesMesa(mesa)">
        <h3>Mesa: {{ mesa.mesa_id }}</h3>
        <!-- <p>Billar: {{ mesa.billar }}</p> -->
        <p>Total Tiempo: {{ formatTime(mesa.total_tiempo) }}</p>
        <p>Total Dinero: {{ formatCurrency(mesa.total_dinero) }}</p>
      </div>
      <button class="cerrar-btn" @click="cerrarDetalles">Cerrar</button>
    </div>

    <div v-if="detallesMesa" class="detalles-mesa">
  <h2>Detalles de la Mesa: {{ detallesMesa.mesa_id }}</h2>
  <div class="ciclo-item" v-for="ciclo in detallesMesa.ciclos" :key="ciclo.id">
    <div class="ciclo-info">
      <span>Fecha Inicio: {{ ciclo.fecha_inicio }}</span>
      <span>Duración: {{ formatTime(ciclo.duracion) }}</span>
      <span>Dinero: {{ formatCurrency(ciclo.dinero) }}</span>
    </div>
  </div>
  <button class="cerrar-btn" @click="cerrarDetalles">Cerrar</button>
</div>



    <div v-if="showModal" class="modal-overlay">
      <div class="modal-container">
        <h3>Mensaje</h3>
        <p>{{ modalMessage }}</p>
        <button v-if="modalAction" @click="modalAction">Sí</button>
        <button @click="showModal = false">Cerrar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../plugins/axios';

export default {
  data() {
    return {
      dias: [],
      mesas: [],
      ciclo: [],
      detallesMesa: null,
      fechaSeleccionada: '',
      totalGeneral: {
        tiempo: 0,
        dinero: 0,
      },
      detallesDiaSeleccionado: null, // Para almacenar los detalles de la fecha seleccionada
      errorMessage: '',
      showModal: false,
      modalMessage: '',
      modalAction: null,
      selectedDias: [],
    };
  },
  mounted() {
    this.fetchDataPorDia();
  },
  methods: {

    cerrarDetalles() {
    this.detallesMesa = null;
  },
     // Función para mostrar un mensaje modal
  showModalMessage(message, action = null) {
    this.modalMessage = message;
    this.modalAction = action;
    this.showModal = true;
  },


  mostrarDetallesMesa(mesa) {
    // Asignar los detalles de la mesa seleccionada a `detallesMesa`
    this.detallesMesa = {
      mesa_id: mesa.mesa_id,
      ciclos: mesa.ciclos
    };
  },
     // Nueva función para obtener las mesas por fecha
  async fetchMesasPorFecha(fecha) {
    try {
      const response = await axios.get(`/tiempo-mesas/mesas-por-fecha?fecha=${fecha}`);
      if (response && response.data) {
        this.mesas = response.data.mesas.map(mesa => ({
          mesa_id: mesa.mesa_id,
          billar: mesa.billar,
          total_tiempo: mesa.total_tiempo,
          total_dinero: mesa.total_dinero,
          ciclos: mesa.ciclos || []  // Aquí se asume que los ciclos están en la respuesta de la API
        }));
        this.fechaSeleccionada = fecha;
        this.errorMessage = '';
      } else {
        this.errorMessage = 'Datos de mesas no recibidos correctamente.';
        this.showModalMessage(this.errorMessage);
      }
    } catch (error) {
      console.error('Error al obtener las mesas:', error);
      this.errorMessage = error.response?.data?.message || 'Error al obtener las mesas';
      this.showModalMessage(this.errorMessage);
    }
  },
  async fetchDataPorDia() {
    try {
      const response = await axios.get('/tiempo-mesas/por-dia-grupo');
      if (response && response.data) {
        const data = response.data;
        this.dias = data.tiempo_por_dia.map(dia => ({
          fecha: dia.fecha,
          total_tiempo: dia.total_tiempo,
          total_dinero: dia.total_dinero,
        }));
        this.totalGeneral = {
          tiempo: this.dias.reduce((acc, dia) => acc + dia.total_tiempo, 0),
          dinero: this.dias.reduce((acc, dia) => acc + dia.total_dinero, 0),
        };
        this.errorMessage = '';
      } else {
        this.errorMessage = 'Datos no recibidos correctamente.';
        this.showModalMessage(this.errorMessage);
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      this.errorMessage = error.response?.data?.message || 'Error al obtener los datos';
      this.showModalMessage(this.errorMessage);
    }
  },

    async mostrarDetallesDia(fecha) {
      try {
        const response = await axios.get(`/tiempo-mesas/detalles?fecha=${fecha}`);
        if (response && response.data) {
          this.detallesDiaSeleccionado = {
            fecha,
            ciclos: response.data.tiempos,
          };
        } else {
          this.showModalMessage('No se encontraron detalles para esta fecha.');
        }
      } catch (error) {
        console.error('Error al obtener los detalles del día:', error);
        this.showModalMessage('Error al obtener los detalles del día.');
      }
    },
 
    confirmarMarcarSeleccionadosPagados() {
      if (this.selectedDias.length === 0) {
        this.showModalMessage('No has seleccionado ningún día.');
        return;
      }
      this.showModalMessage('¿Estás seguro de que quieres marcar los días seleccionados como pagados?', this.marcarSeleccionadosPagados);
    },
    marcarSeleccionadosPagados() {
      axios.post('/tiempo-mesas/marcar-pagados', {
        fechas: this.selectedDias,
      })
        .then(response => {
          const data = response.data;
          if (data.success) {
            this.fetchDataPorDia();
            this.showModal = false;
            this.showModalMessage('Los días seleccionados han sido marcados como pagados');
          } else {
            console.error('Error al marcar los días como pagados:', data.message);
            this.showModalMessage('Error al marcar los días como pagados: ' + data.message);
          }
        })
        .catch(error => {
          console.error('Error al marcar los días como pagados:', error);
          this.showModalMessage('Error al marcar los días como pagados: ' + error.message);
        });
    },
    formatTime(hours) {
      const totalSeconds = hours * 3600;
      const h = Math.floor(totalSeconds / 3600);
      const m = Math.floor((totalSeconds % 3600) / 60);
      const s = Math.floor(totalSeconds % 60);
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value);
    }
  }
};
</script>

<style scoped>
/* ... estilos previos ... */

.detalles-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #e9ecef;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.detalles-container h2 {
  margin-bottom: 10px;
}

.detalles-container ul {
  list-style-type: none;
  padding: 0;
}

.detalles-container li {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detalles-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.detalles-container button:hover {
  background-color: #0056b3;
}
</style>

<style scoped>
.tiempo-mesas {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.detalles-mesa {
  margin-top: 20px;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.detalles-mesa h2 {
  margin-bottom: 15px;
  color: #333;
}

.ciclo-item {
  padding: 15px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ciclo-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ciclo-info span {
  margin-right: 10px;
  font-size: 14px;
  color: #555;
  font-weight: bold;
}

.ciclo-item:hover {
  background-color: #e0f7fa; /* Cambia el color de fondo al pasar el mouse */
}


h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.total-general {
  margin-bottom: 20px;
}

.dias-container {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.dia-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
}

.dia-header {
  /* display: flex; */
  align-items: center;
}

.dia-header h2 {
  margin-left: 10px;
}

p {
  margin: 5px 0;
}

.error-message {
  color: red;
  font-weight: bold;
}

.mark-paid-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 10px 10px 10px;
}

.mark-paid-button:hover {
  background-color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
</style>
