<template>
    <div>
      <h1>Órdenes por camarera</h1>
      <div v-for="waitress in waitresses" :key="waitress.waitress_id" class="waitress-group">
        <h2>{{ waitress.waitress_name }}</h2>
        <ul>
          <li v-for="order in waitress.orders" :key="order.order_id" class="order-card">
            Pedido ID: {{ order.order_id }} 
            - Ítems: {{ order.total_items }} 
            - Monto: {{ order.amount }} 
            - Estado: {{ order.is_paid ? 'Pagado' : 'No pagado' }}
            - Fecha: {{ order.created_at }}
            <button v-if="!order.is_paid" @click="markAsPaid(order.order_id)">Marcar como pagado</button>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  
  export default {
    data() {
      return {
        waitresses: []
      };
    },
    mounted() {
      this.fetchOrders();
    },
    methods: {
      fetchOrders() {
        axios.get('/waitresses/todo')
          .then(response => {
            this.waitresses = response.data.data.map(waitress => ({
              ...waitress,
              orders: waitress.orders.map(order => ({
                ...order,
                is_paid: !!order.is_paid // Convertir a booleano
              }))
            }));
          })
          .catch(error => {
            console.error('Error fetching orders:', error);
          });
      },
      markAsPaid(orderId) {
        axios.post(`/waitresses/mark-as-paid`, { order_id: orderId })
          .then(() => {
            this.fetchOrders(); // Refetch the orders after marking one as paid
          })
          .catch(error => {
            console.error('Error marking order as paid:', error);
          });
      }
    }
  };
  </script>
  
  <style scoped>
  .waitress-group {
    margin-bottom: 20px;
  }
  
  .waitress-group h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .order-card {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .order-card button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .order-card button:hover {
    background-color: #45a049;
  }
  
  .order-card .order-info {
    display: flex;
    flex-direction: column;
  }
  
  .order-card .order-info span {
    margin-bottom: 5px;
    font-size: 1em;
    color: #666;
  }
  </style>
  