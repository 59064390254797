<template>
  <div class="details-container">
    <div class="resumen-ventas">
      <h2>Resumen de Ventas</h2>
      <button @click="$router.push('/HistorialRecaudos')">Historial de Recaudos</button>
      <div class="filter-container">
        <label for="fecha-inicio">Fecha de inicio:</label>
        <input type="datetime-local" v-model="fechaInicio" id="fecha-inicio">
        <label for="fecha-fin">Fecha de fin:</label>
        <input type="datetime-local" v-model="fechaFin" id="fecha-fin">
        <button @click="fetchResumen">Obtener Resumen</button>
        <h3 v-if="totalRecaudado !== 0">Total Recaudado: {{ formatCurrency(totalRecaudado) }}</h3>
        <button @click="markAsPaid">Marcar como Pagado</button>
      </div>
      <div class="table-container">
        <table v-if="resumen.length">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Mesera</th>
              <th>Cantidad Vendida</th>
              <th>Total Recaudado</th>
              <th>Pagado</th>
              <th>Inventario Restante</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in resumen" :key="item.product_name + item.waitress_name">
              <td>{{ item.product_name }}</td>
              <td>{{ item.waitress_name }}</td>
              <td>{{ item.total_quantity }}</td>
              <td>{{ formatCurrency(item.total_amount) }}</td>
              <td>{{ item.is_paid ? 'Sí' : 'No' }}</td>
              <td>{{ item.stock }}</td>
            </tr>
          </tbody>
        </table>
        <p v-else-if="!errorMessage">No hay datos para el rango de fechas seleccionado.</p>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
    </div>
    <!-- Notification for Confirmation -->
    <transition name="fade">
      <div v-if="showConfirmModal" class="notification-overlay">
        <div class="notification">
          <p>{{ confirmMessage }}</p>
          <div class="buttons">
            <button class="button is-success" @click="executeConfirmAction">Sí</button>
            <button class="button is-danger" @click="showConfirmModal = false">No</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from '../plugins/axios';

export default {
  data() {
    return {
      fechaInicio: '',
      fechaFin: '',
      resumen: [],
      totalRecaudado: 0,
      errorMessage: '',
      showConfirmModal: false,
      confirmMessage: '',
      confirmAction: null
    };
  },
  mounted() {
    this.fetchFechasNoPagadas();
    this.fetchResumenSinFiltro();
  },
  methods: {
    formatDate(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      const hours = ('0' + d.getHours()).slice(-2);
      const minutes = ('0' + d.getMinutes()).slice(-2);
      const seconds = ('0' + d.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    formatDateForInput(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      const hours = ('0' + d.getHours()).slice(-2);
      const minutes = ('0' + d.getMinutes()).slice(-2);
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },
    fetchFechasNoPagadas() {
      axios.get('/fecha')
        .then(response => {
          const data = response.data;
          if (data && data.fecha_mas_antigua_no_pagada && data.fecha_mas_reciente_no_pagada) {
            this.fechaInicio = this.formatDateForInput(data.fecha_mas_antigua_no_pagada);
            this.fechaFin = this.formatDateForInput(data.fecha_mas_reciente_no_pagada);
          } else {
            this.errorMessage = 'Formato de respuesta inesperado';
          }
        })
        .catch(error => {
          console.error('Error al obtener las fechas no pagadas:', error);
          this.errorMessage = error.response.data.message || 'Error al obtener las fechas no pagadas';
        });
    },
    fetchResumen() {
      const formattedFechaInicio = this.formatDate(this.fechaInicio);
      const formattedFechaFin = this.formatDate(this.fechaFin);

      axios.post('/resumen-ventas', {
        fecha_inicio: formattedFechaInicio,
        fecha_fin: formattedFechaFin
      })
      .then(response => {
        const data = response.data;
        if (data && data.resumen && Array.isArray(data.resumen)) {
          this.resumen = data.resumen;
          this.totalRecaudado = data.total_recaudado || 0;
          this.errorMessage = '';
        } else {
          this.errorMessage = 'Formato de respuesta inesperado';
        }
      })
      .catch(error => {
        console.error('Error al obtener el resumen de ventas:', error);
        this.errorMessage = error.response.data.message || 'Error al obtener el resumen de ventas';
      });
    },
    fetchResumenSinFiltro() {
      axios.get('/resumen-ventas/sin-filtro')
      .then(response => {
        const data = response.data;
        if (data && data.resumen && Array.isArray(data.resumen)) {
          this.resumen = data.resumen;
          this.totalRecaudado = data.total_recaudado || 0;
          this.errorMessage = '';
        } else {
          this.errorMessage = 'Formato de respuesta inesperado';
        }
      })
      .catch(error => {
        console.error('Error al obtener el resumen de ventas sin filtro de fechas:', error);
        this.errorMessage = error.response.data.message || 'Error al obtener el resumen de ventas sin filtro de fechas';
      });
    },
    markAsPaid() {
      const formattedFechaInicio = this.formatDate(this.fechaInicio);
      const formattedFechaFin = this.formatDate(this.fechaFin);

      this.confirmMessage = '¿Está seguro de que desea marcar como pagado?';
      this.showConfirmModal = true;
      this.confirmAction = () => {
        axios.post('/mark-as-paid', {
          fecha_inicio: formattedFechaInicio,
          fecha_fin: formattedFechaFin
        })
        .then(response => {
          console.log(response.data.message);
          this.showConfirmModal = false;
          this.fetchResumen();
        })
        .catch(error => {
          console.error('Error al marcar como pagado:', error);
          this.errorMessage = error.response.data.message || 'Error al marcar como pagado';
          this.showConfirmModal = false;
        });
      };
    },
    executeConfirmAction() {
      if (this.confirmAction) {
        this.confirmAction();
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'COP' }).format(value);
    }
  }
}
</script>


<style scoped>
.details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.resumen-ventas {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-container label,
.filter-container input,
.filter-container button {
  width: 100%;
}

.filter-container button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 10px;
  border: 1px solid #ccc;
}

th {
  background-color: #f4f4f4;
}

.error-message {
  color: red;
}

.notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.notification .buttons {
  margin-top: 20px;
}

.notification .button {
  margin: 0 10px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
