<template>
    <div>
      <h1>Órdenes no pagadas por camarera</h1>
      <router-link to="/camareradetalles">
          <!-- <button>Ir a Otra Vista</button> -->
        </router-link>
      <div v-for="waitress in waitresses" :key="waitress.waitress_id" class="waitress-group">
        <h3>Camarera: {{ waitress.waitress_name }}</h3>
        <p>Total Ítems: {{ waitress.total_items }}</p>
        <p>Total Monto: {{ waitress.total_amount }}</p>
        <button @click="markAllAsPaid(waitress.waitress_id)">Marcar todos como pagados</button>
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from '../plugins/axios';
  import '../assets/general.css';  // Import the CSS file
  
  export default {
    data() {
      return {
        waitresses: []
      };
    },
    mounted() {
      this.fetchUnpaidOrders();
    },
    methods: {
      fetchUnpaidOrders() {
        axios.get('/waitresses/unpaid-orders')
          .then(response => {
            this.waitresses = response.data.data;
          })
          .catch(error => {
            console.error('Error fetching unpaid orders:', error);
          });
      },
      markAllAsPaid(waitressId) {
      axios.post(`/waitresses/mark-as-paid`, { waitress_id: waitressId })
        .then(() => {
          this.fetchUnpaidOrders(); // Refetch the orders after marking all as paid
        })
        .catch(error => {
          console.error('Error marking orders as paid:', error);
        });
    }
  }
  };
  </script>
  
  <style scoped>
.waitress-group {
  margin-bottom: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}
.waitress-group h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}
.waitress-group p {
  margin: 5px 0;
}
.waitress-group button {
  /* background-color: #4CAF50; */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.waitress-group button:hover {
  /* background-color: #45a049; */
}
</style>
