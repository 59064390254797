<template>
    <div class="details-container">
        <div class="con">
      <h1>Detalles de Tiempos Cobrados</h1>
      <div class="card-list">
        <div class="card" v-for="tiempo in tiemposCobrados" :key="tiempo.grupo">
          <h2>Grupo: {{ formatoGrupo(tiempo.grupo) }}</h2>
          <p><strong>Total Tiempo:</strong> {{ tiempo.total_tiempo }}</p>
          <p><strong>Total Cobrado:</strong> {{ tiempo.total_cobrado }}</p>
          <p><strong>Fecha Cobro:</strong> {{ tiempo.fecha_cobro }}</p>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import axios from '../plugins/axios'; // Asegúrate de que la ruta sea correcta
  
  export default {
    data() {
      return {
        tiemposCobrados: []
      };
    },
    created() {
      this.fetchTiemposCobrados();
    },
    methods: {
      async fetchTiemposCobrados() {
        try {
          const response = await axios.get(`/tiempos-cobrados/sumar`);
          this.tiemposCobrados = response.data;
        } catch (error) {
          console.error('Error fetching tiempos cobrados:', error);
        }
      },
      formatoGrupo(grupo) {
        const [inicio, fin] = grupo.split(' - ');
        return `${inicio} - ${fin}`;
      }
    }
  };
  </script>
  
  <style scoped>
  .details-container {
    padding: 20px;
  }
  
  .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    max-width: 100%;
    box-sizing: border-box;
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: translateY(-10px);
  }
  
  h2 {
    margin-top: 0;
  }
  
  p {
    margin: 5px 0;
  }
  .con{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: #f0f2f5;
    min-height: 100vh;
}
  </style>
  