<template>
  <div class="inventory-container">
    <h2>Inventario de Categorías y Productos</h2>

    <!-- Botones para agregar categorías y productos -->
    <div class="button-group">
      <button @click="showCategoryModal = true">Agregar Categoría</button>
      <button @click="showAddProductModal">Agregar Producto</button>
    </div>

    <!-- Barra de búsqueda -->
    <div class="search-bar">
      <input v-model="searchQuery" placeholder="Buscar producto..." />
    </div>

    <!-- Tabla de Productos -->
    <table class="product-table">
      <thead>
        <tr>
          <th>Nombre del Producto</th>
          <th>Categoría</th>
          <th>Precio</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in filteredProducts" :key="product.id">
          <td>{{ product.name }}</td>
          <td>{{ product.category ? product.category.name : 'Desconocido' }}</td>
          <td>{{ product.price }}</td>
          <td>
            <!-- Botón para actualizar -->
            <button @click="showEditProductModal(product)">Editar</button>
            <!-- Botón para eliminar -->
            <button @click="deleteProduct(product.id)">Eliminar</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Mostrar mensaje de éxito o error -->
    <p v-if="message">{{ message }}</p>

    <!-- Modal para agregar categoría -->
    <div v-if="showCategoryModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showCategoryModal = false">&times;</span>
        <form @submit.prevent="addCategory">
          <div class="form-group">
            <label for="categoryName">Nombre de Categoría:</label>
            <input v-model="newCategory" id="categoryName" required />
            <button type="submit">Agregar Categoría</button>
          </div>
        </form>
        <p v-if="message">{{ message }}</p>
      </div>
    </div>

    <!-- Modal para agregar y editar producto -->
    <div v-if="showProductModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeProductModal">&times;</span>
        <form @submit.prevent="isEditing ? updateProduct() : addProduct()">
          <div class="form-group">
            <label for="productName">Nombre del Producto:</label>
            <input v-model="newProduct.name" id="productName" required />
          </div>
          <div class="form-group">
            <label for="productCategory">Categoría:</label>
            <select v-model="newProduct.categoryId" id="productCategory" required>
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="productPrice">Precio:</label>
            <input type="number" v-model="newProduct.price" id="productPrice" required />
          </div>
          <button type="submit">{{ isEditing ? 'Actualizar Producto' : 'Agregar Producto' }}</button>
        </form>
        <p v-if="message">{{ message }}</p>
      </div>
    </div>
  
  <!-- Ventana modal de confirmación para eliminar producto -->
<div v-if="confirmDeleteProductId !== null" class="modal">
  <div class="modal-content">
    <p>¿Estás seguro de que deseas eliminar este producto?</p>
    <div class="button-group">
      <button @click="confirmDeleteProduct">Confirmar</button>
      <button @click="confirmDeleteProductId = null">Cancelar</button>
    </div>
  </div>
</div>
</div>
</template>

<script>
import axios from '../plugins/axios'; // Asegúrate de que la ruta sea correcta

export default {
  data() {
    return {
      showCategoryModal: false,
      showProductModal: false,
      isEditing: false,
      searchQuery: '',
      newCategory: '',
      confirmDeleteProductId: null,
      newProduct: {
        id: null, // Agregar id para identificar productos existentes
        name: '',
        categoryId: null,
        price: 0,
      },
      categories: [],
      products: [],
      message: '',
    };
  },
  computed: {
    filteredProducts() {
      return this.products.filter(product =>
        product.name && product.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    fetchCategories() {
      axios.get('/categorias')
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => {
          console.error('Error al cargar las categorías:', error);
        });
    },
    addCategory() {
      const newCategory = {
        name: this.newCategory,
      };

      axios.post('/categorias', newCategory)
        .then(response => {
          this.categories.push(response.data);
          this.newCategory = '';
          this.showCategoryModal = false;
          this.message = 'Categoría agregada con éxito';
          this.clearMessageAfterTimeout();
        })
        .catch(error => {
          console.error('Error al guardar la categoría:', error);
          this.message = 'Error al agregar la categoría';
          this.clearMessageAfterTimeout();
        });
    },
    clearMessageAfterTimeout() {
      setTimeout(() => {
        this.message = '';
      }, 4000); // Tiempo en milisegundos
    },
    showAddProductModal() {
      this.isEditing = false;
      this.clearProductForm();
      this.showProductModal = true;
    },
    showEditProductModal(product) {
      this.isEditing = true;
      this.newProduct = { ...product, categoryId: product.category_id }; // Copia el producto y asigna categoryId
      this.showProductModal = true;
    },
    closeProductModal() {
      this.showProductModal = false;
      this.clearProductForm();
    },
    addProduct() {
      const newProduct = {
        name: this.newProduct.name,
        categoryId: this.newProduct.categoryId,
        price: this.newProduct.price,
      };

      axios.post('/productos', newProduct)
        .then(response => {
          if (response.data && response.data.success) {
            this.products.push(response.data.data);
            this.clearProductForm();
            this.showProductModal = false;
            this.message = response.data.message || 'Producto agregado con éxito';
            this.fetchProducts();
          } else {
            this.message = response.data.message || 'Error al agregar el producto';
          }
          this.clearMessageAfterTimeout();
        })
        .catch(error => {
          console.error('Error al guardar el producto:', error);
          this.message = 'Error al agregar el producto';
          this.clearMessageAfterTimeout();
        });
    },
    updateProduct() {
      const updatedProduct = {
        name: this.newProduct.name,
        categoryId: this.newProduct.categoryId,
        price: this.newProduct.price,
      };

      axios.put(`/productos/${this.newProduct.id}`, updatedProduct)
        .then(response => {
          if (response.data && response.data.success) {
            const index = this.products.findIndex(product => product.id === this.newProduct.id);
            if (index !== -1) {
              this.products.splice(index, 1, response.data.data);
            }
            this.clearProductForm();
            this.showProductModal = false;
            this.message = response.data.message || 'Producto actualizado con éxito';
            this.fetchProducts();
          } else {
            this.message = response.data.message || 'Error al actualizar el producto';
          }
          this.clearMessageAfterTimeout();
        })
        .catch(error => {
          console.error('Error al actualizar el producto:', error);
          this.message = 'Error al actualizar el producto';
          this.clearMessageAfterTimeout();
        });
    },
    clearProductForm() {
      this.newProduct = {
        id: null, // Reiniciar el id al limpiar el formulario
        name: '',
        categoryId: null,
        price: 0,
      };
    },
 
    deleteProduct(productId) {
    // Establece el ID del producto a eliminar
    this.confirmDeleteProductId = productId;
  },
    getCategoryName(categoryId) {
      const category = this.categories.find(cat => cat.id === categoryId);
      return category ? category.name : 'Desconocido';
    },
    fetchProducts() {
      axios.get('/productos')
        .then(response => {
          this.products = response.data;
        })
        .catch(error => {
          console.error('Error al cargar los productos:', error);
        });
    },
    confirmDeleteProduct() {
  // Elimina el producto una vez confirmada la eliminación
  axios.delete(`/productos/${this.confirmDeleteProductId}`)
    .then(response => {
      if (response.data && response.data.success) {
        this.products = this.products.filter(product => product.id !== this.confirmDeleteProductId);
        this.message = response.data.message || 'Producto eliminado con éxito';
      } else {
        this.message = response.data.message || 'Producto eliminado con éxito';
        this.fetchProducts();
      }
      this.clearMessageAfterTimeout();
    })
    .catch(error => {
      console.error('Error al eliminar el producto:', error);
      this.message = 'Error al eliminar el producto';
      this.clearMessageAfterTimeout();
    })
    .finally(() => {
      // Reinicia la variable de confirmación de eliminación
      this.confirmDeleteProductId = null;
    });
},

  },
  
  mounted() {
    this.fetchCategories();
    this.fetchProducts();

    //  // Escuchar eventos del backend para actualizar productos
    //  this.$root.$on('productAdded', product => {
    //     this.products.push(product);
    //   });

    //   this.$root.$on('productUpdated', updatedProduct => {
    //     const index = this.products.findIndex(product => product.id === updatedProduct.id);
    //     if (index !== -1) {
    //       this.$set(this.products, index, updatedProduct);
    //     }
    //   });

    //   this.$root.$on('productDeleted', productId => {
    //     this.products = this.products.filter(product => product.id !== productId);
    //   });

  },
};
</script>


<style scoped>
.inventory-container {
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

button {
  padding: 10px 15px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
}

.search-bar {
  margin-bottom: 20px;
  text-align: center;
}

.search-bar input {
  padding: 10px;
  width: 80%;
  max-width: 500px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.product-table th, .product-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.product-table th {
  background-color: #f4f4f4;
  color: #333;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px 40px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

input, select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>