<template>
    <div class="estado-mesas">
      <h1>Estado de Mesas</h1>
      <button @click="fetchEstadoMesas">Actualizar Estado de Mesas</button>
      <div class="mesa-container" v-for="mesa in mesas" :key="mesa.id">
        <p>{{ mesa.nombre }}: <span :class="{'active': mesa.estado, 'inactive': !mesa.estado}">{{ mesa.estado ? 'Activa' : 'Perdió conexión' }}</span></p>
      </div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </template>
  
  <script>
  import axios from '../plugins/axios'; // Asegúrate de que la ruta sea correcta
  
  export default {
    data() {
      return {
        mesas: [],
        errorMessage: '',
      };
    },
    methods: {
      fetchEstadoMesas() {
        axios.get('/esp32/estado-mesas')
          .then(response => {
            this.mesas = response.data.map(mesa => ({
              id: mesa.esp32_id,
              nombre: `Mesa ${mesa.esp32_id}`,
              estado: mesa.estado, // true para activa, false para perdida conexión
            }));
            this.errorMessage = '';
          })
          .catch(error => {
            console.error('Error al obtener el estado de las mesas:', error);
            this.errorMessage = error.response.data.message || 'Error al obtener el estado de las mesas';
          });
      }
    },
    mounted() {
      this.fetchEstadoMesas(); // Obtener el estado de las mesas al montar el componente
    }
  };
  </script>
  
  <style scoped>
  .estado-mesas {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .mesa-container {
    padding: 10px;
    font-size: 1.2em;
    border-bottom: 1px solid #ddd;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
  }
  
  .active {
    color: green;
  }
  
  .inactive {
    color: red;
  }
  </style>
  