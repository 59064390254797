<template>
  <div class="inventory-container">
    <h2>Tomar el Pedido</h2>
    <div class="button-group">
      <button @click="showChooseWaitressModal = true">Agregar Pedido</button>
    </div>
    <p v-if="message">{{ message }}</p>
    <div class="search-bar">
      <input v-model="searchQuery" placeholder="Buscar producto..." />
    </div>
    <div class="table-wrapper">
    <table class="product-table">
      <thead>
        <tr>
          <th>Pedido</th>
          <th>Usuaria</th>
          <th>Cantidad</th>
          <th>Total</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
<tr v-for="order in filteredOrders" :key="order.id">
  <td>{{ order.id }}</td>
  <td>{{ order.waitress.name }}</td>
  <td>{{ order.total_quantity }}</td>
  <td>{{ order.total_value | currency }}</td>
  <td>
    <button @click="showDetailsOrder(order)">Detalles</button>
  </td>
</tr>
</tbody>

    </table>
  </div>

    <!-- Modal para seleccionar camarera -->
    <div v-if="showChooseWaitressModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showChooseWaitressModal = false">&times;</span>
        <h3>Seleccionar Camarera</h3>
        <div class="waitress-cards">
          <div v-for="waitress in waitresses" :key="waitress.id" class="waitress-card" @click="selectWaitress(waitress)">
            <h4>{{ waitress.name }}</h4>
            <p>ID: {{ waitress.id }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para tomar pedido -->
    <div v-if="showPedidoModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closePedidoModal">&times;</span>
        <h3>Tomar Pedido</h3>
        <div v-for="(item, index) in newOrder.items" :key="index" class="form-group">
          <label>Producto:</label>
          <select v-model="item.product_id" @change="updateItemPrice(item)">
            <option v-for="product in products" :key="product.id" :value="product.id">{{ product.name }}</option>
          </select>
          <label>Precio:</label>
          <input type="number" v-model.number="item.price" :readonly="true" />
          <label>Cantidad:</label>
          <input type="number" v-model.number="item.quantity" min="1" />
        </div>
        <button @click="addItem">Agregar Producto</button>
        <button @click="addOrder">Tomar Pedido</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../plugins/axios';
import { DEFAULT_CUSTOMER_NAME } from '../plugins/axios';

export default {
  data() {
    return {
      showChooseWaitressModal: false,
      selectedWaitress: null,
      showPedidoModal: false,
      searchQuery: '',
      newOrder: {
      customer_name: DEFAULT_CUSTOMER_NAME,   
        waitress_id: null,
        items: [{ product_id: null, price: 0, quantity: 0, customer_name: DEFAULT_CUSTOMER_NAME }],
      },
      waitresses: [], 
      products: [],
      orders: [],
      message: '',
    };
  },
  computed: {
    filteredOrders() {
      return this.orders.filter(order =>
        order.customer_name && order.customer_name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    clearMessageAfterTimeout() {
      setTimeout(() => {
        this.message = '';
      }, 4000);
    },
    closePedidoModal() {
      this.showPedidoModal = false;
      this.clearOrderForm();
    },
    addItem() {
      this.newOrder.items.push({ product_id: null, price: 0, quantity: 0 });
    },
    clearOrderForm() {
      this.newOrder = {
        customer_name: '',
        waitress_id: null,
        items: [{ product_id: null, price: 0, quantity: 0 }],
      };
    },
    addOrder() {
  this.newOrder.waitress_id = this.selectedWaitress.id;
  axios.post('/orders', this.newOrder)
      .then(response => {
          if (response.data && response.data.data) {
              this.orders.push(response.data.data.order);
              this.clearOrderForm();
              this.showPedidoModal = false;
              this.message = response.data.message || 'Pedido tomado con éxito';
              this.fetchOrders();
          } else {
              this.message = response.data.message || 'Error al tomar el pedido';
          }
          this.clearMessageAfterTimeout();
      })
      .catch(error => {
          console.error('Error al tomar el pedido:', error);
          this.message = error.response.data.message || 'Error al tomar el pedido';
          this.clearMessageAfterTimeout();
      });
},
    fetchOrders() {
      axios.get('/orders')
        .then(response => {
          this.orders = response.data;
        })
        .catch(error => {
          console.error('Error al cargar los pedidos:', error);
        });
    },
    fetchWaitresses() {
      axios.get('/waitresses')
        .then(response => {
          this.waitresses = response.data;
        })
        .catch(error => {
          console.error('Error al cargar las camareras:', error);
        });
    },
    fetchProducts() {
      axios.get('/productos')
        .then(response => {
          this.products = response.data;
        })
        .catch(error => {
          console.error('Error al cargar los productos:', error);
        });
    },
    selectWaitress(waitress) {
      this.selectedWaitress = waitress;
      this.showChooseWaitressModal = false;
      this.showPedidoModal = true;
    },
    updateItemPrice(item) {
      const product = this.products.find(product => product.id === item.product_id);
      if (product) {
        item.price = product.price;
      }
    },
  },
  mounted() {
    this.fetchProducts();
    this.fetchWaitresses();
    this.fetchOrders();
  },
};
</script>

<style scoped>
.inventory-container {
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
}

.product-table th,
.product-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.product-table th {
  background-color: #f2f2f2;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

button[type="button"] {
  margin-top: 10px;
}

.message {
  margin-top: 20px;
  padding: 10px;
  background-color: #e0ffe0;
  border: 1px solid #008000;
  border-radius: 5px;
  color: #008000;
}

.waitress-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.waitress-card {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  flex: 1 0 calc(33.333% - 10px);
  box-sizing: border-box;
}

.waitress-card:hover {
  background-color: #e0e0e0;
}
.table-wrapper {
max-height: 600px; /* Ajusta la altura según sea necesario */
overflow-y: auto;
}
</style>
