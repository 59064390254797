<template>
    <div class="waitress-container">
      <h2>Gestión de Camareras</h2>
  
      <!-- Botón para agregar camarera -->
      <div class="button-group">
        <button @click="showWaitressModal = true">Agregar Camarera</button>
      </div>
  
      <!-- Barra de búsqueda -->
      <div class="search-bar">
        <input v-model="searchQuery" placeholder="Buscar camarera..." />
      </div>
  
      <!-- Tabla de Camareras -->
      <table class="waitress-table">
        <thead>
          <tr>
            <th>Nombre de la Camarera</th>
            <th>Número</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="waitress in filteredWaitresses" :key="waitress.id">
            <td>{{ waitress.name }}</td>
            <td>{{ waitress.number }}</td>
            <td>
              <!-- Botón para actualizar -->
              <button @click="showEditWaitressModal(waitress)">Editar</button>
              <!-- Botón para eliminar -->
              <button @click="deleteWaitress(waitress.id)">Eliminar</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Mostrar mensaje de éxito o error -->
      <p v-if="message">{{ message }}</p>
  
      <!-- Modal para agregar y editar camarera -->
      <div v-if="showWaitressModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeWaitressModal">&times;</span>
          <form @submit.prevent="isEditing ? updateWaitress() : addWaitress()">
            <div class="form-group">
              <label for="waitressName">Nombre de la Camarera:</label>
              <input v-model="newWaitress.name" id="waitressName" required />
              <label for="waitressNumber">Número de Camarera:</label>
              <input v-model="newWaitress.number" id="waitressNumber" required />
            </div>
            <button type="submit">{{ isEditing ? 'Actualizar Camarera' : 'Agregar Camarera' }}</button>
          </form>
          <p v-if="message">{{ message }}</p>
        </div>
      </div>
  
      <!-- Ventana modal de confirmación para eliminar camarera -->
      <div v-if="confirmDeleteWaitressId !== null" class="modal">
        <div class="modal-content">
          <p>¿Estás seguro de que deseas eliminar esta camarera?</p>
          <div class="button-group">
            <button @click="confirmDeleteWaitress">Confirmar</button>
            <button @click="confirmDeleteWaitressId = null">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  
  export default {
    data() {
      return {
        showWaitressModal: false,
        isEditing: false,
        searchQuery: '',
        newWaitress: {
          id: null,
          name: '',
          number: '',
        },
        confirmDeleteWaitressId: null,
        waitresses: [],
        message: '',
      };
    },
    computed: {
      filteredWaitresses() {
        return this.waitresses.filter(waitress =>
          waitress.name && waitress.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      },
    },
    methods: {
      fetchWaitresses() {
        axios.get('/waitresses')
          .then(response => {
            this.waitresses = response.data;
          })
          .catch(error => {
            console.error('Error al cargar las camareras:', error);
          });
      },
      addWaitress() {
        const newWaitress = {
          name: this.newWaitress.name,
            number: this.newWaitress.number,
        };
  
        axios.post('/waitresses', newWaitress)
          .then(response => {
            this.waitresses.push(response.data);
            this.newWaitress.name = '';
            this.newWaitress.number = '';
            this.showWaitressModal = false;
            this.message = 'Camarera agregada con éxito';
            this.clearMessageAfterTimeout();
          })
          .catch(error => {
            console.error('Error al guardar la camarera:', error);
            this.message = 'Error al agregar la camarera';
            this.clearMessageAfterTimeout();
          });
      },
      clearMessageAfterTimeout() {
        setTimeout(() => {
          this.message = '';
        }, 4000);
      },
      showEditWaitressModal(waitress) {
        this.isEditing = true;
        this.newWaitress = { ...waitress };
        this.showWaitressModal = true;
      },
      closeWaitressModal() {
        this.showWaitressModal = false;
        this.isEditing = false;
        this.newWaitress = { id: null, name: '', number: ''};
      },
      updateWaitress() {
  axios.put(`/waitresses/${this.newWaitress.id}`, this.newWaitress)
    .then(response => {
      if (response.status === 200) {
        const index = this.waitresses.findIndex(waitress => waitress.id === this.newWaitress.id);
        if (index !== -1) {
          this.waitresses.splice(index, 1, this.newWaitress);
        }
        this.showWaitressModal = false;
        this.message = 'Camarera actualizada con éxito';
      } else {
        this.message = response.data.message || 'Error al actualizar la camarera';
      }
      this.clearMessageAfterTimeout();
    })
    .catch(() => {
      this.message = 'Error al actualizar la camarera';
      this.clearMessageAfterTimeout();
    });
},

    deleteWaitress(waitressId) {
      this.confirmDeleteWaitressId = waitressId;
    },
    confirmDeleteWaitress() {
  axios.delete(`/waitresses/${this.confirmDeleteWaitressId}`)
    .then(response => {
      if (response.status === 204) {
        this.waitresses = this.waitresses.filter(waitress => waitress.id !== this.confirmDeleteWaitressId);
        this.message = 'Camarera eliminada con éxito';
      } else {
        this.message = response.data.message || 'Error al eliminar la camarera';
      }
      this.confirmDeleteWaitressId = null;
      this.clearMessageAfterTimeout();
    })
    .catch(error => {
      console.error('Error al eliminar la camarera:', error);
      this.message = 'Error al eliminar la camarera';
      this.confirmDeleteWaitressId = null;
      this.clearMessageAfterTimeout();
    });
},

  },
  mounted() {
    this.fetchWaitresses();
  },
};
</script>

<style scoped>
.waitress-container {
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

button {
  padding: 10px 15px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
}

.search-bar {
  margin-bottom: 20px;
  text-align: center;
}

.search-bar input {
  padding: 10px;
  width: 80%;
  max-width: 500px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.waitress-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.waitress-table th, .waitress-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.waitress-table th {
  background-color: #f4f4f4;
  color: #333;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px 40px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

input, select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>
